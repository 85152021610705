<template>
	<div class="page">
		<div class="title">保险管理</div>

		<el-divider></el-divider>

		<!-- table -->
		<el-table id="table" :data="tableData" :header-cell-style="tableHeaderStyle" :cell-style="tableCellStyle"
			style="width: 100%;">
			<el-table-column prop="name" label="姓名"></el-table-column>
			<el-table-column prop="type" label="用工形式"></el-table-column>
			<el-table-column prop="company" label="合同公司"></el-table-column>
			<el-table-column prop="post" label="岗位"></el-table-column>
			<el-table-column prop="base_pay" label="性别"></el-table-column>
			<el-table-column prop="net_pay" label="年龄"></el-table-column>
			<el-table-column prop="bonus" label="保险种类"></el-table-column>
			<el-table-column label="操作" min-width="180" fixed="right">
				<el-dropdown size="mini" split-button type="primary">
					操作
					<el-dropdown-menu slot="dropdown">
						<el-dropdown-item>编辑</el-dropdown-item>
						<el-dropdown-item>删除</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
				<!-- <div class="operations">
          <div>编辑</div>
          <div>删除</div>
        </div> -->
			</el-table-column>
		</el-table>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				search: {
					keyword: "",
					date: "",
				},
				tableData: [],

				tableHeaderStyle: {
					backgroundColor: "#F5F8FA",
					textAlign: "center",
				},
				tableCellStyle: {
					textAlign: "center",
				},
			};
		},
		methods: {},
	};
</script>

<style lang="scss" scoped>
	.title {
		font-size: 14px;
		font-weight: bold;
		color: #1c2438;
	}

	.operations {
		display: flex;
		justify-content: center;
		align-items: center;

		>div {
			font-size: 14px;
			color: #406bff;
			cursor: pointer;

			&:not(:last-child) {
				margin-right: 13px;
			}
		}
	}
</style>
